import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Layout from "./pages/Layout";

import loadable from "@loadable/component";
import ProfileStatistics from "./pages/ProfileStatistics";
import TournoiStatistics from "./pages/TournoiStatistics";

const LeaderBoard = loadable(() => import("./pages/LeaderBoard"));
const Portfolio = loadable(() => import("./pages/Portfolio"));
// const EventsPage = loadable(() => import("./pages/EventsPage"));
const TournamentsPage = loadable(() => import("./pages/TournamentsPage"));
const Affiliate = loadable(() => import("./pages/Affiliate"));
// const BlogPage = loadable(() => import("./pages/BlogPage"));
const Gladiators = loadable(() => import("./pages/Gladiator"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="/leaderboard" element={<Layout />}>
          <Route index element={<LeaderBoard />} />
        </Route>
        {/* <Route path="/portfolio" element={<Layout />}>
          <Route index element={<Portfolio />} />
        </Route>
        <Route path="/portfolio/statistics/:id" element={<Layout />}>
          <Route index element={<ProfileStatistics />} />
        </Route> */}
        {/* <Route path="/calendar" element={<Layout />}>
          <Route index element={<EventsPage />} />
        </Route>
        <Route path="/settings" element={<Layout />}>
          <Route index element={<div></div>} />
        </Route>
        <Route path="/blog" element={<Layout />}>
          <Route index element={<BlogPage />} />
        </Route> */}
        <Route path="/contests" element={<Layout />}>
          <Route index element={<TournamentsPage />} />
        </Route>
        <Route path="/contests/statistics/:id" element={<Layout />}>
          <Route index element={<TournoiStatistics />} />
        </Route>
        <Route path="/Affiliate" element={<Layout />}>
          <Route index element={<Affiliate />} />
        </Route>
        <Route path="/Gladiator" element={<Layout />}>
          <Route index element={<Gladiators />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
