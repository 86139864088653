import React from "react";
import logo from "../assets/PNG/logo.png";
import { c_navbar_items as NavItems } from "../context/C_NavBar";
import { Link, useLocation } from "react-router-dom";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

export default function NavBar() {
  let location = useLocation();
  location = location.pathname;
  return (
    <div className="flex w-20">
      <nav className="flex">
        <ul className="flex-col">
          <li className="flex h-20">
            <Link to="/">
              <img src="http://tickticktrader.com/wp-content/uploads/2024/01/contest.png" alt="logo" className="object-contain" />
            </Link>
          </li>
          {NavItems?.map((item, index) => {
            return (
              <Link
                key={index.toString()}
                to={item.link}
                id={"tooltip" + item.name}
                data-tooltip-content={item.name}
                data-tooltip-place="right"
              >
                <Tooltip
                  anchorId={"tooltip" + item.name}
                  style={{
                    backgroundImage: `linear-gradient(rgb(1, 116, 214) 0%, rgb(1, 44, 142) 100%)`,
                    zIndex: "9999",
                  }}
                />
                <div className="grid grid-cols-[.5rem_auto] group cursor-pointer">
                  <div className="group-hover:opacity-100 opacity-0 relative flex p-[.75rem_0px_.75rem_0px]">
                    <div className="absolute h-[50%] top-[25%] w-3 rounded-full bg-gradient-to-t bg-blue-900 blur-sm"></div>
                    <div
                      className="z-10 h-full w-full bg-gradient-to-t border-[.075rem] border-border-color rounded-r-lg group"
                      style={{
                        backgroundImage: `linear-gradient(rgb(1, 116, 214) 0%, rgb(1, 44, 142) 100%)`,
                      }}
                    ></div>
                  </div>
                  <div>
                    <li className={`flex h-20 p-5 `}>
                      <div className=" p-2 h-10 w-10 rounded-full text-2xl group-hover:bg-[linear-gradient(#0174d6,#012c8e)] bg-[linear-gradient(#01298b,#0a0b16)]">
                        {item.icon}
                      </div>
                    </li>
                  </div>
                </div>
              </Link>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
