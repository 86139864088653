import { React, useState } from "react";
import { activeInactiveIcon, upDownIcon } from "../context/C_BestOfTraders";
import { c_highlight_items } from "../context/C_Highlight";
import axios from "axios";
import arrow from "../assets/SVG/arrow.svg";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

import {
  accountType,
  CURRENT_TOURNOI_URL,
  fetchCurrentTournaments,
} from "../context/provider/tournamentsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GiFlamingArrow } from "react-icons/gi";
import { TiArrowLoopOutline } from "react-icons/ti";

function QueryClientProviderWraper(params) {
  let { prefixSize, showOnlyInactives } = params;
  let [tournamentList, setTournamentList] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const updateDimensions = () => {
    setWidth(window.screen.width);
  };

  const getUrl = `${CURRENT_TOURNOI_URL}${params.id}`;

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const { isLoading, error } = useQuery(
    ["tournamentList"],
    async () => {
      const { data } = await axios.get(getUrl);

			if (data) {
				let obj = [...data];
				obj = obj?.map((item, index) => {
					return {
						...item,
						accountType: index > 3 ? "standard" : accountType[index],
					};
				});
				setTournamentList(obj);
			}
			return data;
		},
		{
			refetchInterval: 1000,
			//refetchInterval: 1000,
			//refetchInterval: 60000,
		}
	);

  if (isLoading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <img
          src="https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif"
          alt=""
          className={`w-24 h-24 object-contain`}
        />
      </div>
    );

  if (error) {
    console.log("An error has occurred: " + error);
    return "An error has occurred";
  }

  prefixSize = prefixSize === 0 ? 100 : prefixSize;
  prefixSize = width < 768 ? 100 : prefixSize;

//  console.log(tournamentList);

  const top6 = {
	"QQ-Ismail": {
		contry: 'Morocco',
		name: "Ismail B",
	},
	"QQ267251": {
		contry: 'USA',
		name: "Roberto M",
	},
	"QQ-Wayne": {
		contry: 'Spain',
		name: "Wayne F",
	},
	"QQ267249": {
		contry: 'France',
		name: "Adnane B",
	},
	"QQ-Byron": {
		contry: 'Ecuador',
		name: "Byron V",
	},
	"QQ-Sergio": {
		contry: 'Spain',
		name: "Sergio F",
	},
};
//  const top6 = {
//	"QQ267252": {
//		contry: 'Morocco',
//		name: "Ismail B",
//	},
//	"QQ267251": {
//		contry: 'USA',
//		name: "Roberto M",
//	},
//	"QQ267250": {
//		contry: 'Spain',
//		name: "Wayne F",
//	},
//	"QQ267249": {
//		contry: 'France',
//		name: "Adnane B",
//	},
//	"QQ267248": {
//		contry: 'Ecuador',
//		name: "Byron V",
//	},
//	"QQ267247": {
//		contry: 'Spain',
//		name: "Sergio F",
//	},
//};


  return (
    <div
      className={
        tournamentList?.length
          ? `rounded-3xl md:overflow-hidden overflow-scroll w-[_100vw] md:w-full `
          : `rounded-3xl md:overflow-hidden w-full  pb-4 pt-1`
      }
      style={{
        backgroundImage: "linear-gradient(180deg, #01298B 0%, #0A0B16 100%)",
      }}
    >
      {/*  w-[_100vw] */}
      {showOnlyInactives &&
      tournamentList
        ?.filter((e) => e.status !== "active")
        ?.slice(0, prefixSize ?? 50).length === 0 ? (
        <p className="p-28 text-center text-xl">
          <b>
            All traders are still competiting - none have been eliminated yet !
          </b>
        </p>
      ) : tournamentList?.length ? (
        <table
          className={`relative rounded-t-3xl text-left  md:table-auto md:overflow-hidden md:w-full table-fixed overflow-scroll w-[_100rem]`}
        >
          <thead className="bg-[linear-gradient(_transparent,_transparent,#1D5F85)] h-12">
            <tr>
              <th className="w-20"></th>
              <th className="w-14">Rank</th>
              <th className="w-20"></th>
              <th>Pays</th>
              <th>Traders name</th>
              <th>Account ID</th>
              <th>Account Balance</th>
              <th></th>
              <th>Cash On Hand</th>
              <th>P&L</th>
              {/*<th>Weekly P&L</th>*/}
              <th>Status</th>
              <th className="w-20"></th>
            </tr>
          </thead>
          <tbody className="relative">
            {tournamentList
              // ?.filter((e) => (showOnlyInactives ? e.status !== "active" : e))
              ?.sort((a, b) => {
                if (a.balance === b.balance)
                  return b.accountId > a.accountId ? -1 : 1;
                else return b.balance - a.balance;
              })
							?.slice(0, 50)
							//?.slice(0, prefixSize ?? 50)
              ?.map((item, index) => {
                return (
                  <tr className="h-12" key={index}>
                    <td className="pr-6 pl-6">
                      <img
                        src={
                          c_highlight_items.filter((e) => {
                                if (index < 20) return e.name === "gold";
                                return e.name === "standard";
                              })[0]?.icon
                        }
                        alt=""
                      />
                    </td>
                    <td>
                      {index + 1 === item?.prevrank ? (
                        <div className="bg-white flex items-center justify-center text-yellow-600 mr-2 shadow-lg p-2 rounded-xl">
                          <TiArrowLoopOutline className="text-xs md:text-xl" />
                        </div>
                      ) : item?.prevrank > index + 1 ? (
                        <div className="bg-white flex items-center justify-center text-green-600 mr-2 shadow-lg p-2 rounded-xl">
                          <div>
                            <img src={upDownIcon.up} alt="" />
                          </div>
                          {item?.prevrank - index - 1}
                        </div>
                      ) : (
                        <div className="bg-white flex items-center justify-center text-red-600 mr-2 shadow-lg p-2 rounded-xl">
                          <img src={upDownIcon.down} alt="" />
                          {Math.abs(item?.prevrank - index - 1)}
                        </div>
                      )}
                    </td>
                    <td> {index + 1}</td>
                    <td>
                      <img
                        src={`http://tickticktrader.com/wp-content/uploads/2024/01/${
                          top6[item?.accountId]?.contry
                        }.png`}
                        alt=""
                        className="w-8 object-contain"
                      />
                    </td>
                    <td>
                      {
                        top6[item?.accountId]?.name
                      }
                    </td>
                    <td>{item?.accountId}</td>
                    <td>{item?.balance?.toFixed(2)}$</td>
                    <td
                      className={
                        item?.status === "active"
                          ? "text-red-500"
                          : "text-green-500"
                      }
                    >
                      {item?.Percentage}
                    </td>
                    <td>{item?.cashOnHand?.toFixed(2)}$</td>
                    <td>{(item?.balance - item?.cashOnHand)?.toFixed(2)}$</td>
                    {/*<td>
											{item?.weeklypnl?.toFixed(2)?.toString().concat("$") ??
												"N/A"}
										</td>*/}
                    <td>{item?.status === "active" ? "active" : "inactive"}</td>
                    <td>
                      <img
                        src={
                          item?.status === "active"
                            ? activeInactiveIcon.active
                            : activeInactiveIcon.inactive
                        }
                        alt=""
                        className="h-8 ml-4"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="text-center   h-40 text-base  lg:text-xl my-[100px] font-bold 	">
          {/*The tournament has just been launched, the statistics will be here
          soon. Stay Tuned!
		  */}
          Quantum Quest Finals <br />
          <small>will start soon</small>
        </div>
      )}
    </div>
  );
}

export default function BestTrader(params) {
  const { prefixSize } = params;
  const dispatch = useDispatch();
  const getCurrentTournaments = useSelector((state) => state.tableId);
  const [showOnlyInactives, setShowOnlyInactives] = useState(false);

  const [visibilityIndex, setVisibilityIndex] = useState(0);
  // const [isPaused, setIsPaused] = useState(true);

  // useEffect(() => {
  //   let inter = setInterval(() => {
  //     if (isPaused) {
  //       if (visibilityIndex < getCurrentTournaments?.value?.length - 1)
  //         setVisibilityIndex(visibilityIndex + 1);
  //       else setVisibilityIndex(0);
  //       console.log(visibilityIndex);
  //     } else console.log("paused");
  //   }, 3000);

  //   return () => clearInterval(inter);
  // }, [visibilityIndex, getCurrentTournaments?.value?.length, isPaused]);

  useEffect(() => {
    if (getCurrentTournaments.state === "idle")
      dispatch(fetchCurrentTournaments());
  });

  if (getCurrentTournaments.state === "loading")
    return (
      <div className="w-full h-full flex justify-center items-center">
        <img
          src="https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif"
          alt=""
          className={`w-24 h-24 object-contain`}
        />
      </div>
    );
  if (getCurrentTournaments.state === "failed") return "Failed to fetch data";

  const queryClient = Array(getCurrentTournaments?.value?.length)
    .fill()
    .map((_, index) => new QueryClient());

  return (
    <>
      {/*<div
        className={`p-4 bg-primary-color rounded-xl mb-4 text-center font-bold cursor-pointer ${
          prefixSize !== 0 && "hidden"
        }`}
        onClick={() => setShowOnlyInactives(() => !showOnlyInactives)}
      >
        {showOnlyInactives
          ? "Take a look on all accounts"
          : "Take a look on the inactive accounts"}
      </div>*/}
      <div className="grid grid-cols-[_2rem,_auto,_2rem] md:grid-cols-[_2rem,_auto,_2rem] pt-8">
        <div
          className={`pt-6 p-1 ${
            !getCurrentTournaments ||
            (getCurrentTournaments?.value?.length === 0 && "hidden")
          }`}
          onClick={() =>
            setVisibilityIndex((prev) => {
              if (prev > 0) return prev - 1;
              else return getCurrentTournaments?.value?.length - 1;
            })
          }
        >
          {/* <img src={arrow} alt="" className="opacity-75 rotate-180" /> */}
        </div>
        {getCurrentTournaments?.value?.length === 0 ||
          (!getCurrentTournaments?.value?.length && (
            <div className="h-full w-full flex justify-center items-center p-48">
              <h1 className="text-2xl font-bold text-center">
                No tournaments are currently running
              </h1>
            </div>
          ))}
        {getCurrentTournaments?.value?.map((item, index) => (
          <div
            key={index}
            className={
              index !== visibilityIndex
                ? `hidden`
                : "overflow-hidden md:overflow-visible"
            }
            // onMouseEnter={() => setIsPaused(() => false)}
            // onMouseLeave={() => setIsPaused(() => true)}
          >
            <div className="w-full grid grid-cols-[auto] pb-3 gap-2">
              {/* <div className="flex">
                <img
                  src={item?.image?.url}
                  alt={item?.accountsprefix}
                  className="max-h-16 rounded-lg"
                />
              </div> */}
              <div className="flex flex-col justify-center">
                <h1 className="text-xl font-bold">
                  {item?.name} leaderboard{" "}
                  <span className="text-cyan-500"></span>
                </h1>
              </div>
            </div>
            <QueryClientProvider client={queryClient[index]}>
              <QueryClientProviderWraper
                prefixSize={prefixSize}
                id={item.id}
                showOnlyInactives={showOnlyInactives}
              />
            </QueryClientProvider>
          </div>
        ))}
        <div
          className={`pt-6 p-1 ${
            !getCurrentTournaments ||
            (getCurrentTournaments?.value?.length === 0 && "hidden")
          }`}
          onClick={() =>
            setVisibilityIndex((prev) => {
              if (prev < getCurrentTournaments?.value?.length - 1)
                return prev + 1;
              else return 0;
            })
          }
        >
          {/* <img src={arrow} alt="" className="opacity-75" /> */}
        </div>
      </div>
    </>
  );
}
