import React from "react";
import { useParams } from "react-router-dom";
import Section from "../components/Section";
import sapphireicon from "../assets/SVG/sapphire.svg";
import PortfolioCard from "../components/PortfolioCard";

import axios from "axios";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const queryClient = new QueryClient();
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

let labels = [];

let chartData = {};

function QueryClientProviderWraper() {
  const { id } = useParams();

  const { isLoading, error, data } = useQuery(["accountBalance"], async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API_URL}/user/profile/${id}`
    );
    let dataset = [];

    data?.user?.accounts?.forEach((item) => {
      item?.data?.forEach((element) => {
        labels.push(moment(element?.date).format("DD/MM/YYYY"));
        dataset.push(element?.balance);
      });
    });

    chartData = {
      labels,
      datasets: [
        {
          label: "Account Balance",
          data: Array.isArray(dataset) ? dataset : [],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
    return data;
  });

  if (isLoading) return <div className="w-full h-full flex justify-center items-center">
  <img src="https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif" alt="" className={
    `w-24 h-24 object-contain`
  }/>
</div>;

  if (error) {
    console.log("An error has occurred: " + error);
    return "An error has occurred";
  }

  return (
    <Section
      icons={sapphireicon}
      title="Tournaments"
      subtitle="List of tournaments"
    >
      <div className="p-10">
        <h1 className="text-3xl">Account Balance</h1>
        <Line options={options} data={chartData} height={50} />

        <div className="p-10 flex flex-wrap gap-10 justify-center">
          {data?.user?.accounts?.map((items, index) => (
            <PortfolioCard
              key={index}
              nickname={items?.tournoi?.name}
              id={items?.tournoi?.id}
              thumbnail={items?.tournoi?.image?.url}
              isTour={true}
            />
          ))}
        </div>
      </div>
    </Section>
  );
}

export default function ProfileStatistics() {
  return (
    <div className="p-8 pt-0">
      <QueryClientProvider client={queryClient}>
        <QueryClientProviderWraper />
      </QueryClientProvider>
    </div>
  );
}
