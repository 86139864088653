import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BLOG_URL = `${process.env.REACT_APP_BASE_API_URL}/blog`;

let initialState = {
  data: [],
  status: "idle",
};

export const fetchBlog = createAsyncThunk("blog/getBlog", async () => {
  try {
    const response = await axios.get(BLOG_URL);
    return response.data;
  } catch (error) {}
});

export const getBlogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchBlog.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { selectBlog } = getBlogSlice.actions;
export default getBlogSlice.reducer;
