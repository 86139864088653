import { React, useEffect } from "react";
import ArticleList from "./ArticleList";

import { useSelector, useDispatch } from "react-redux";
import { fetchCurrentTournaments } from "../context/provider/tournamentsSlice";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

export default function CurrentT() {
  const dispatch = useDispatch();
  const currentTournaments = useSelector((state) => state.currentTournaments);
  const setCurrentId = useSelector((state) => state.setCurrentId);

  useEffect(() => {
    if (currentTournaments.status === "idle")
      dispatch(fetchCurrentTournaments());
  }, [currentTournaments, currentTournaments.status, dispatch]);

  if (currentTournaments.status === "loading")
    return (
      <div className="w-full h-full flex justify-center items-center">
        <img
          src="https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif"
          alt=""
          className={`w-24 h-24 object-contain`}
        />
      </div>
    );

  return (
    <div className="relative h-full w-full">
      {currentTournaments?.data?.length === 0 ? (
        <div className="h-full w-full flex justify-center items-center pb-28">
          <h1 className="text-2xl font-bold text-center">
            No tournaments are currently running
          </h1>
        </div>
      ) : (
        <Swiper
          spaceBetween={0}
          loop={false}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className={`mySwiper -mt-16`}
          onAnimationEnd={(swiper) => {
            setCurrentId(currentTournaments?.data[swiper.realIndex]?._id);
          }}
        >
          {currentTournaments?.data?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="p-10 pt-24 md:pt-20 relative md:grid md:grid-cols-2 flex flex-col md:flex-col-reverse gap-4">
                  <div
                    className={`flex justify-center items-center object-contain `}
                    //style={{
                    //	backgroundImage:`url(http://res.cloudinary.com/dms9cpmnk/image/upload/v1701620082/kyraa1vu8cwkzxakr9zc.jpg)`,
                    //	backgroundSize:"contain",
                    //	backgroundPosition:"center",
                    //	backgroundRepeat:"no-repeat"
                    //}}
                  >
                    <img
                      src="http://tickticktrader.com/wp-content/uploads/2024/01/cover.png"
                      alt=""
                      className="object-cover h-[300px] md:mr-[30px] rounded-xl"
                    />
                  </div>

                  <div className="flex justify-center items-start flex-col">
                    {/* <ArticleList arts={item?.val} prefixLen={3} /> */}
                    <h1 className="text-xl pb-4">
                      <b>Prize pool of</b>
                    </h1>
                    <img src="http://tickticktrader.com/wp-content/uploads/2024/01/50k.png" alt="" className="object-contain h-20" />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
}
