import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import name from "../../assets/PNG/contestlg.png";
import participents from "../../assets/PNG/type.png";
import prize from "../../assets/PNG/prizes.png";

export const TOURNOI_URL = `${process.env.REACT_APP_BASE_API_URL}/tournoi/current`;

const initialState = {
  data: [],
  tournaments: [],
  status: "idle",
  currentid: "",
};

export let CURRENT_TOURNOI_URL = `${process.env.REACT_APP_BASE_API_URL}/account/tournoi/`;

export const fetchCurrentTournaments = createAsyncThunk(
  "tournoi/getCurrentTournaments",
  async () => {
    try {
      const response = await axios.get(TOURNOI_URL);
      return response.data;
    } catch (error) {}
  }
);

export const accountType = {
  0: "gold",
  1: "silver",
  2: "bronze",
  3: "standard",
};

export const currentTournamentsSlice = createSlice({
  name: "currentTournaments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentTournaments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCurrentTournaments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = [];
        action.payload?.forEach((element) => {
          let obj = {
            val: [
              {
                id: element.id,
                content: element.name,
                sub: `Contest`,
                icon: name,
              },
              {
                id: element.id,
                content: element.type,
                sub: "Account type",
                icon: participents,
              },
              {
                id: element.id,
                content: element?.prizes?.slice(0, 3),
                sub: "QQ prizes",
                icon: prize,
                fullcontent: element?.prizes,
              },
            ],
            eventPic: element.image.url,
          };
          state.tournaments.push(element.id);
          state.data.push(obj);
        });
      })
      .addCase(fetchCurrentTournaments.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setCurrentTournament } = currentTournamentsSlice.actions;
export default currentTournamentsSlice.reducer;
