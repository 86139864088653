import React from "react";
import sapphireicon from "../assets/PNG/astro.png";
import BestTrader from "../components/BestTrader";
import Highlights from "../components/Highlights";
import Section from "../components/Section";
import logo from "../assets/PNG/logo.png";

import leaderboard from "../assets/SVG/leaderboard-white.svg";
// import BlogsPerview from "../components/BlogsPreview";
// import EventsPreview from "../components/EventsPreview";
import CurrentT from "../components/CurrentT";
import WeeklyPNL from "../components/WeeklyPNL";

export default function HomePage() {
	return (
		<div className="p-8 pt-0">
			<div className={`w-full grid md:grid-cols-1 gap-10`}>
				{/*<div className="hidden md:block">*/}
				{/*<Section
            icons={sapphireicon}
            title="Last Contest"
            subtitle="Highlights"
          >
            <Highlights />
          </Section>*/}
				{/*</div>*/}
				<Section
					icons="http://tickticktrader.com/wp-content/uploads/2024/01/contest.png"
					title="Current Contest"
					bluetitle=""
					subtitle=""
				>
					<CurrentT />
				</Section>
			</div>
			<Section
				icons="http://tickticktrader.com/wp-content/uploads/2024/01/astro-2.png"
				title="Heads up"
				subtitle="QQ Finals"
				withoutBG={false}
				bottomButtom={{
					title: "Go to the Leaderboard",
					icon: leaderboard,
					withShadow: true,
					link: "/leaderboard",
				}}
			>
				<BestTrader />
			</Section>
			{/* <Section
        icons={sapphireicon}
        title="Best of Traders in the last week"
        subtitle="Top 20 traders in current tournament"
        withoutBG={false}
        bottomButtom={{
          title: "Go to the Leaderboard",
          icon: leaderboard,
          withShadow: true,
          link: "/leaderboard",
        }}
      >
        <WeeklyPNL />
      </Section> */}
		</div>
	);
}
