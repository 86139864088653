import { createSlice } from "@reduxjs/toolkit";

export const setCurrentTournoiIdSlice = createSlice({
  name: "setCurrentTournoiId",
  initialState: {
    id: "",
    count: 0,
  },
  reducers: {
    setCurrentParticipant: (state, action) => {
      state.count = action.payload;
    },
    setCurrentId: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentId, setCurrentParticipant } =
  setCurrentTournoiIdSlice.actions;
export default setCurrentTournoiIdSlice.reducer;
