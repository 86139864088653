import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { TOURNOI_URL } from "./tournamentsSlice";

export const fetchTournoiId = createAsyncThunk(
	"tournoi/getCurrentTournaments",
	async () => {
	  try {
		const response = await axios.get(TOURNOI_URL);
		return response.data;
	  } catch (error) {}
	}
  );

export const setTableIdSlice = createSlice({
	  name: "setTableId",
	  initialState: {
		value : [],
		state : "idle",
	  },
	  reducers : {
		setTableId : (state, action) =>{
			state.value = action.payload;
		}
	  },
	  extraReducers: (builder) => {
		builder
		  .addCase(fetchTournoiId.pending, (state) => {
			state.state = "loading";
		  })
		  .addCase(fetchTournoiId.fulfilled, (state, action) => {
			state.state = "succeeded";
			state.value = action.payload;
		  })
		  .addCase(fetchTournoiId.rejected, (state) => {
			state.state = "failed";
		  });
	  },
});

export const { setTableId } = setTableIdSlice.actions;
export default setTableIdSlice.reducer;