import { configureStore } from "@reduxjs/toolkit";
import showHideFeedReducer from "./showHideFeedSlice";
import currentTournamentsReducer from "./tournamentsSlice";
import nextEventReducer from "./NextEventSlice";
import blogReducer from "./BlogSlice";
import portfolioReducer from "./PortfolioSlice";
import setCurrentTournoiIdReducer from "./setCurrentTournoiId";
import mobileNavBarReducer from "./MobileNavBarSlice";
import setTableIdReducer from "./setTableIdSlice";
import searchReducer from "./SearchSlice";
import usersAffiliate from "./AffiliateSlice";

export default configureStore({
  reducer: {
    feedVisibility: showHideFeedReducer,
    currentTournaments: currentTournamentsReducer,
    nextEvent: nextEventReducer,
    blog: blogReducer,
    user: portfolioReducer,
    currentTournoiId: setCurrentTournoiIdReducer,
    mobileNavBar: mobileNavBarReducer,
    tableId: setTableIdReducer,
    search: searchReducer,
    affilate: usersAffiliate,
  },
});
