import { React } from "react";

import { Link } from "react-router-dom";

export default function Section(params) {
  let { icons, title, subtitle, bluetitle, bottomButtom, withoutBG } = params;

  return (
    <div className="grid grid-rows-[6rem_auto]">
      <div className="w-full grid grid-cols-[5rem_auto]">
        <div className="flex">
          <img src={icons} alt={title} className="object-contain pr-4" />
        </div>
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl">
            <b>{title}</b> <span className="text-cyan-500">{bluetitle}</span>
          </h1>
          <p
            className={`text-sm ${
              subtitle === "QQ Contest" ? "text-cyan-500" : ""
            }`}
          >
            {subtitle}
          </p>
        </div>
      </div>
      <div
        className={`w-full ${withoutBG ?? " overflow-hidden"} rounded-3xl`}
        style={{
          backgroundImage: "linear-gradient(180deg, #01298B 0%, #0A0B16 100%)",
        }}
      >
        {params.children}
      </div>
      {/*{bottomButtom && (
        <div className="relative -top-32 mb-10 md:block hidden">
          {bottomButtom?.withShadow && (
            <div className="absolute  w-full h-36 opacity-25 rounded-b-3xl"></div>
          )}
          <div
            className=" absolute grid grid-cols-[3rem,_auto] w-64 rounded-xl pr-2 pl-2 left-1/2 -translate-x-1/2 top-16"
            style={{
              backgroundImage:
                "linear-gradient(180deg, #0174d6 0%, #012c8e 100%)",
            }}
          >
            <div>
              <img src={bottomButtom.icon} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <Link to={bottomButtom.link}>{bottomButtom.title}</Link>
            </div>
          </div>
        </div>
      )}*/}
    </div>
  );
}
