import { createSlice } from "@reduxjs/toolkit";

export const mobileNavBarSlice = createSlice({
  name: "mobileNavBar",
  initialState: {
    value: false,
  },
  reducers: {
    showMobileNavBar: (state) => {
      state.value = !state.value;
    },
  },
});

export const { showMobileNavBar } = mobileNavBarSlice.actions;
export default mobileNavBarSlice.reducer;
