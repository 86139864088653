import goldicon from '../assets/SVG/gold.svg';
import silvericon from '../assets/SVG/silver.svg';
import bronzeicon from '../assets/SVG/bronze.svg';
import standardicon from '../assets/SVG/standard.svg';

export const c_highlight_items = [
	{
		name: "gold",
		icon: goldicon,
		text: "text-gold"
	},
	{
		name: "silver",
		icon: silvericon,
		text: "text-silver"
	},
	{
		name: "bronze",
		icon: bronzeicon,
		text: "text-bronze"
	},
	{
		name: "standard",
		icon: standardicon,
		text: "text-hover-gradient-4"
	}
];