import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const USER_URL = `${process.env.REACT_APP_BASE_API_URL}/user/affiliate`;

let initialState = {
  data: [],
  status: "idle",
};

export const fetchUsersAffiliate = createAsyncThunk(
  "user/getUserAffiliate",
  async () => {
    try {
      const response = await axios.get(USER_URL);
      return response.data;
    } catch (error) {}
  }
);

export const getAffiliateUsersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAffiliate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersAffiliate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUsersAffiliate.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { selectUsersAffiliate } = getAffiliateUsersSlice.actions;
export default getAffiliateUsersSlice.reducer;
