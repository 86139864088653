import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Section from "../components/Section";
import sapphireicon from "../assets/SVG/sapphire.svg";
import PortfolioCard from "../components/PortfolioCard";

import { GiFlamingArrow } from "react-icons/gi";
import { TiArrowLoopOutline } from "react-icons/ti";
import axios from "axios";

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import {
  accountType,
  CURRENT_TOURNOI_URL,
} from "../context/provider/tournamentsSlice";
import { c_highlight_items } from "../context/C_Highlight";
import { activeInactiveIcon, upDownIcon } from "../context/C_BestOfTraders";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
  scales: {
    x: {
      ticks: {
        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
        callback: function (val, index) {
          // Hide every 2nd tick label
          return index % 2 === 0 ? this.getLabelForValue(val) : "";
        },
        color: "white",
        font: {
          size: 14,
          weight: "bold",
        },
      },
    },
    y: {
      ticks: {
        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
        callback: function (val, index) {
          // Hide every 2nd tick label
          return index % 2 === 0 ? this.getLabelForValue(val) : "";
        },
        color: "white",
        font: {
          size: 14,
          weight: "bold",
        },
      },
    },
  },
};

let chartData = {};

function QueryClientProviderWraper() {
  const { id } = useParams();

  const { isLoading, error } = useQuery(["accountBalance"], async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API_URL}/tournoi/profile/${id}`
    );

    let dataSets = [];
    data?.tournoi?.accounts.forEach((element) => {
      let obj = {};

      obj.lable = element?.accountId;
      obj.AccountBalance = [];
      obj.date = [];

      element?.logs?.forEach((element) => {
        obj.AccountBalance.push(element?.balance);
        obj.date.push(moment(element?.date).format("DD/MM/YYYY"));
      });

      dataSets.push(obj);
    });

    let maxLabels = 0;
    let indexMaxLabels = 0;

    dataSets.forEach((element, index) => {
      if (element.date.length > maxLabels) {
        maxLabels = element.date.length;
        indexMaxLabels = index;
      }
    });

    chartData = {
      labels: dataSets[indexMaxLabels]?.date,
      datasets:
        dataSets?.map((element, index) => {
          let color = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
            Math.random() * 255
          )}, ${Math.floor(Math.random() * 255)})`;
          return {
            label: element?.lable,
            data: element?.AccountBalance,
            borderColor: color,
            backgroundColor: color,
          };
        }) ?? [],
    };

    return data;
  });

  if (isLoading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <img
          src="https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif"
          alt=""
          className={`w-24 h-24 object-contain`}
        />
      </div>
    );

  if (error) {
    console.log("An error has occurred: " + error);
    return "An error has occurred";
  }

  return (
    <Section
      icons={sapphireicon}
      title="Tournaments"
      subtitle="List of tournaments"
    >
      <div className="p-10">
        <h1 className="text-3xl">Account Balance</h1>
        <Line data={chartData} options={options} />
        <div className="p-10 flex flex-wrap gap-10 justify-center"></div>
      </div>
    </Section>
  );
}

function GetListOfUsers() {
  const { id } = useParams();

  const { isLoading, error, data } = useQuery(["ListOfUsers"], async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_API_URL}/account/tournoi/${id}`
    );

    return data;
  });

  if (isLoading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <img
          src="https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif"
          alt=""
          className={`w-24 h-24 object-contain`}
        />
      </div>
    );
  if (error) {
    console.log("An error has occurred: " + error);
    return "An error has occurred";
  }
  return (
    <div>
      <h1 className="text-3xl">List of users</h1>
      <div className="p-10 flex flex-wrap gap-10 justify-center">
        {data
          ?.filter((e) => e?.user?.id)
          .map((element, index) => {
            return (
              <PortfolioCard
                key={index}
                id={element?.user?.id}
                nickname={element?.accountId}
                thumbnail={`${element?.user?.firstName?.[0]}${element?.user?.lastName?.[1]}`}
                isTour={false}
              />
            );
          })}
      </div>
    </div>
  );
}

export default function TournoiStatistics() {
  const { id } = useParams();
  const queryClient = new QueryClient();

  return (
    <div className="p-8 pt-8">
      {/* <QueryClientProvider client={queryClient}>
        <QueryClientProviderWraper />
      </QueryClientProvider> */}

      <QueryClientProvider client={queryClient}>
        <GetLeaderBoard id={id} />
      </QueryClientProvider>
      <div className="h-10"></div>

      <QueryClientProvider client={queryClient}>
        <GetListOfUsers />
      </QueryClientProvider>
    </div>
  );
}

function GetLeaderBoard(params) {
  let { prefixSize, showOnlyInactives } = params;
  let [tournamentList, setTournamentList] = useState([]);
  const [width, setWidth] = useState(window.screen.width);
  const updateDimensions = () => {
    setWidth(window.screen.width);
  };

  const getUrl = `${CURRENT_TOURNOI_URL}${params.id}`;

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const { isLoading, error } = useQuery(
    ["tournamentList"],
    async () => {
      const { data } = await axios.get(getUrl);

      if (data) {
        let obj = [...data];
        obj = obj?.map((item, index) => {
          return {
            ...item,
            accountType: index > 3 ? "standard" : accountType[index],
          };
        });
        setTournamentList(obj);
      }
      return data;
    },
    {
      refetchInterval: 60000 * 3,
    }
  );

  if (isLoading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <img
          src="https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif"
          alt=""
          className={`w-24 h-24 object-contain`}
        />
      </div>
    );

  if (error) {
    console.log("An error has occurred: " + error);
    return "An error has occurred";
  }

  prefixSize = prefixSize === 0 ? 50 : prefixSize;
  prefixSize = width < 768 ? 50 : prefixSize;
  console.log(tournamentList);
  return (
    <div
      className={`rounded-3xl md:overflow-hidden overflow-scroll w-[_100vw] md:w-full `}
      style={{
        backgroundImage: "linear-gradient(180deg, #01298B 0%, #0A0B16 100%)",
      }}
    >
      {/*  w-[_100vw] */}
      {showOnlyInactives &&
      tournamentList
        ?.filter((e) => e.status !== "active")
        ?.slice(0, prefixSize ?? 20).length === 0 ? (
        <p className="p-28 text-center text-xl">
          <b>
            All traders are still competiting - none have been eliminated yet !
          </b>
        </p>
      ) : tournamentList?.length ? (
        <table
          className={`relative rounded-t-3xl text-left  md:table-auto md:overflow-hidden md:w-full table-fixed overflow-scroll w-[_100rem]`}
        >
          <thead className="bg-[linear-gradient(_transparent,_transparent,#1D5F85)] h-12">
            <tr>
              <th className="w-20"></th>
              <th className="w-20">Rank</th>
              <th className="w-20"></th>
              <th>Nickname</th>
              <th>Account ID</th>
              <th>Account Balance</th>
              <th></th>
              <th>Cash On Hand</th>
              <th>P&L</th>
              <th>Status</th>
              <th className="w-20"></th>
            </tr>
          </thead>
          <tbody className="relative">
            {tournamentList
              // ?.filter((e) => (showOnlyInactives ? e.status !== "active" : e))
              ?.slice(0, prefixSize ?? 20)
              ?.sort((a, b) => {
                if (a.balance === b.balance)
                  return b.accountId > a.accountId ? -1 : 1;
                else return b.balance - a.balance;
              })
              ?.reverse()
              ?.map((item, index) => {
                return (
                  <tr className="h-12" key={index}>
                    <td className="pr-6 pl-6">
                      <img
                        src={
                          c_highlight_items.filter(
                            (e) => e.name === item.accountType
                          )[0]?.icon
                        }
                        alt=""
                      />
                    </td>
                    <td>
                      {index + 1 === item?.prevrank ? (
                        <div className="bg-white flex items-center justify-center text-yellow-600 mr-2 shadow-lg p-2 rounded-xl">
                          <TiArrowLoopOutline className="text-xl" />
                        </div>
                      ) : item?.prevrank - index + 1 > 0 ? (
                        <div className="bg-white flex items-center justify-center text-green-600 mr-2 shadow-lg p-2 rounded-xl">
                          <img src={upDownIcon.up} alt="" />
                          {item?.prevrank - index + 1}
                        </div>
                      ) : (
                        <div className="bg-white flex items-center justify-center text-red-600 mr-2 shadow-lg p-2 rounded-xl">
                          <img src={upDownIcon.down} alt="" />
                          {Math.abs(item?.prevrank - index + 1)}
                        </div>
                      )}
                    </td>
                    <td> {index + 1}</td>
                    <td>
                      {item?.user?.userId?.replace("TTTU", "TTTContestant") ??
                        `TTTContestant${parseInt(Math.random() * 1000)
                          .toString()
                          .padStart(4, 0)}`}
                    </td>
                    <td>{item?.accountId}</td>
                    <td>{item?.balance?.toFixed(2)}$</td>
                    <td
                      className={
                        item?.status === "active"
                          ? "text-red-500"
                          : "text-green-500"
                      }
                    >
                      {item?.Percentage}
                    </td>
                    <td>{item?.cashOnHand?.toFixed(2)}$</td>
                    <td>{(item?.balance - item?.cashOnHand)?.toFixed(2)}$</td>
                    <td>{item?.status === "active" ? "active" : "inactive"}</td>
                    <td>
                      <img
                        src={
                          item?.status === "active"
                            ? activeInactiveIcon.active
                            : activeInactiveIcon.inactive
                        }
                        alt=""
                        className="h-8 bg-contain"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="text-center   h-40 text-base  lg:text-xl my-[100px] font-bold 	">
          {/*The tournament has just been launched, the statistics will be here
          soon. Stay Tuned!
		  */}
          Quantum Quest Contest <br />
          <small>will start December 4th, 00:00am UTC +1</small>
        </div>
      )}
    </div>
  );
}
