import React from "react";
import { Link, Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import TopNavBar from "../components/TopNavBar";
import notificationIcon from "../assets/SVG/notification-v1.svg";
import notificationActiveIcon from "../assets/SVG/notification-v2.svg";
import { useSelector, useDispatch } from "react-redux";
import { showHideFeed } from "../context/provider/showHideFeedSlice";
import { showMobileNavBar } from "../context/provider/MobileNavBarSlice";
import ActivityFeed from "../components/ActivityFeed";
import { c_navbar_items } from "../context/C_NavBar";
import { useState } from "react";
import {
  clearResults,
  fetchSearchResults,
  setVisibility,
} from "../context/provider/SearchSlice";

import searchicon from "../assets/SVG/searchbar.svg";

import { AiOutlineSearch } from "react-icons/ai";

export default function Layout() {
  const feedVisibility = useSelector((state) => state.feedVisibility.value);
  const navBarVisibility = useSelector((state) => state.mobileNavBar.value);
  const dispatch = useDispatch();

  const [searchType, setSearchType] = useState("tournoi");
  const searchResaults = useSelector((state) => state.search);

  const handleSearch = (e) => {
    e.preventDefault();

    if (e.target.value)
      dispatch(
        fetchSearchResults({ search: e.target.value, searchType: searchType })
      );
    else dispatch(setVisibility(false));
  };

  const handOnFocus = () => {
    if (searchResaults?.searchResults?.length > 0)
      dispatch(setVisibility(true));
    else dispatch(setVisibility(false));
    console.log(searchResaults);
  };

  function searchBar(isDesktop) {
    return (
      <div className="w-full absolute top-10 z-50 pr-5 pl-5">
        <div
          className={`rounded-full border-2 border-ocean-color grid-cols-[2rem_auto_6rem] p-2  relative md:hidden ${
            navBarVisibility ? "grid" : "hidden"
          }`}
        >
          <div className="flex">
            <AiOutlineSearch className="text-2xl pt-2 ml-2" />
          </div>
          <div>
            <input
              type="text"
              className="h-full w-full bg-transparent pl-5 pr-5 focus:border-none focus:outline-none"
              placeholder="Search ..."
              onChange={(e) => handleSearch(e)}
              onBlur={() => dispatch(setVisibility(false))}
              onFocus={() => handOnFocus()}
            />
          </div>
          <div>
            <select
              className="h-full w-full bg-transparent outline-hidden focus:border-none focus:outline-none opacity-50"
              defaultValue={searchType}
              onChange={(e) => {
                dispatch(clearResults());
                setSearchType(() => e.target.value);
              }}
            >
              {/* <option value="user">User</option> */}
              <option value="tournoi">Contests</option>
            </select>
          </div>
          <div
            className={`w-full max-h-56 absolute top-20 z-50 rounded-2xl border-[.125rem] border-border-color bg-blue-900 overflow-x-auto ${
              searchResaults?.searchBarVisibility ? null : "hidden"
            }`}
            style={{
              backgroundImage: `linear-gradient(rgb(1, 116, 214) 0%, rgb(1, 44, 142) 100%)`,
            }}
          >
            {searchResaults?.searchResults?.map((item, index) => (
              <div
                key={index}
                className="h-14 bg-border-color flex justify-center items-center border-2 border-border-color m-2 rounded-xl hover:opacity-70 cursor-pointer font-bold"
              >
                <p>{item?.userId ?? item?.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex h-screen w-screen"
      style={{
        backgroundImage: `url("http://tickticktrader.com/wp-content/uploads/2023/11/Groupe-de-masques-7.png")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="md:block hidden">
        <NavBar />
      </div>
      <div className="grid w-full h-screen grid-rows-[5rem_auto]">
        <TopNavBar />
        <div className="h-full w-full overflow-hidden md:pr-4">
          <div
            className="h-full w-full rounded-t-3xl overflow-y-scroll overflow-x-hidden"
            style={{
              backgroundImage:
                "linear-gradient(180deg, #01298B 0%, #0A0B16 100%)",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>

      <div className="fixed right-0 top-32  hidden grid-cols-[3rem,_auto]">
        <div
          className="h-12 w-12 bg-dark-color rounded-3xl rounded-tr-none rounded-br-none cursor-pointer"
          onClick={() => dispatch(showHideFeed())}
        >
          <img
            src={feedVisibility ? notificationIcon : notificationActiveIcon}
            alt=""
          />
        </div>
        {feedVisibility && <ActivityFeed />}
      </div>
      <div
        className={`absolute h-full w-full opacity-95 z-50 flex flex-col justify-center items-center ${
          navBarVisibility ? null : "hidden"
        }`}
        onClick={() => dispatch(showMobileNavBar())}
        style={{
          backgroundColor: "#0a0d1b",
        }}
      >
        <div className="h-1/2 w-full flex flex-col justify-around items-center">
          {c_navbar_items.map((item, index) => (
            <Link key={index} to={item.link}>
              <h1 className="font-bold">{item.name}</h1>
            </Link>
          ))}
        </div>
      </div>
      {searchBar(false)}
    </div>
  );
}
