import { React, useState } from "react";
import searchicon from "../assets/SVG/searchbar.svg";
import toplogcon from "../assets/PNG/ticktick.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showMobileNavBar } from "../context/provider/MobileNavBarSlice";
import {
  clearResults,
  fetchSearchResults,
  setVisibility,
} from "../context/provider/SearchSlice";
import { useSelector } from "react-redux";

// import discord from "../assets/PNG/discord.png";
// import facebook from "../assets/PNG/facebook.png";
// import instagram from "../assets/PNG/instagram.png";
// import twitter from "../assets/PNG/twitter.png";
// import youtube from "../assets/PNG/youtube.png";

import {
  RiFacebookCircleLine,
  RiInstagramLine,
  RiTwitterLine,
  RiDiscordLine,
  RiYoutubeLine,
} from "react-icons/ri";

import { AiOutlineSearch } from "react-icons/ai";

export default function TopNavBar() {
  const [searchType, setSearchType] = useState("tournoi");
  const searchResaults = useSelector((state) => state.search);
  const [isHover, setIsHover] = useState(false);

  const dispatch = useDispatch();

  const handleSearch = (e) => {
    e.preventDefault();

    if (e.target.value)
      dispatch(
        fetchSearchResults({ search: e.target.value, searchType: searchType })
      );
    else dispatch(setVisibility(false));
  };

  const handOnFocus = () => {
    if (searchResaults?.length > 0) dispatch(setVisibility(true));
    else dispatch(setVisibility(false));
  };

  function searchBar(isDesktop) {
    return (
      <div
        className={`rounded-full grid-cols-[2rem_auto_6rem] p-2 w-1/2 relative ${
          isDesktop ? "md:grid hidden" : "grid"
        }`}
        style={{
          backgroundImage: `linear-gradient(180deg, #01298B 0%, #0A0B16 100%)`,
        }}
      >
        <div className="flex">
          <AiOutlineSearch className="text-2xl pt-2 ml-2" />
        </div>
        <div>
          <input
            type="text"
            className="h-full w-full bg-transparent pl-5 pr-5 focus:border-none focus:outline-none"
            placeholder="Seach contest"
            onChange={(e) => handleSearch(e)}
            onBlur={() => (!isHover ? dispatch(setVisibility(false)) : null)}
            onFocus={() => handOnFocus()}
          />
        </div>
        <div>
          {/* <select
            className="h-full w-full bg-transparent outline-hidden focus:border-none focus:outline-none opacity-50"
            defaultValue={searchType}
            onChange={(e) => {
              dispatch(clearResults());
              setSearchType(() => e.target.value);
            }}
          >
            <option value="tournoi">Tournoi</option>
          </select> */}
        </div>
        <div
          className={`w-full max-h-56 absolute top-20 z-50 rounded-2xl border-[.125rem] border-border-color overflow-x-auto ${
            searchResaults?.searchBarVisibility ? null : "hidden"
          }`}
          style={{
            backgroundImage: `linear-gradient(rgb(1, 116, 214) 0%, rgb(1, 44, 142) 100%)`,
          }}
        >
          {searchResaults?.searchResults?.map((item, index) => (
            <Link
              key={index}
              to={
                item?.userId
                  ? `/portfolio/statistics/${item?.id}`
                  : `/contests/statistics/${item?.id}`
              }
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              onClick={() => dispatch(setVisibility(false))}
            >
              <div className="h-14 bg-border-color flex justify-center items-center border-2 border-border-color m-2 rounded-xl hover:opacity-70 cursor-pointer font-bold">
                <p>{item?.userId ?? item?.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="grid md:grid-cols-[auto_4rem_4rem_4rem_4rem_4rem_5rem] grid-cols-2 h-20 w-full">
      <div className="flex h-full w-full p-4">
        {searchBar(true)}
        <div
          className="h-full w-8 md:hidden flex flex-col justify-evenly pt-2 pb-2"
          onClick={() => {
            dispatch(showMobileNavBar());
          }}
        >
          <div className="w-full h-1 bg-blue-700 rounded-full"></div>
          <div className="w-full h-1 bg-blue-700 rounded-full"></div>
          <div className="w-full h-1 bg-blue-700 rounded-full"></div>
        </div>
      </div>
      <div className="items-center justify-center p-4 h-full w-full hidden md:flex group">
        <a
          href="https://www.facebook.com/TickTickTrader"
          className="group-hover:bg-[linear-gradient(#0174d6,#012c8e)] bg-[linear-gradient(#01298b,#0a0b16)]"
          style={{
            padding: "8px",
            borderRadius: "50px",
          }}
        >
          <RiFacebookCircleLine className="text-3xl" />
        </a>
      </div>
      <div className="items-center justify-center p-4 h-full w-full hidden md:flex group">
        <a
          href="https://www.instagram.com/tickticktrader/"
          className="group-hover:bg-[linear-gradient(#0174d6,#012c8e)] bg-[linear-gradient(#01298b,#0a0b16)]"
          style={{
            padding: "8px",
            borderRadius: "50px",
          }}
        >
          <RiInstagramLine className="text-3xl" />
        </a>
      </div>
      <div className="items-center justify-center p-4 h-full w-full hidden md:flex group">
        <a
          href="https://twitter.com/tickticktrader"
          style={{
            padding: "8px",
            borderRadius: "50px",
          }}
          className="group-hover:bg-[linear-gradient(#0174d6,#012c8e)] bg-[linear-gradient(#01298b,#0a0b16)]"
        >
          <RiTwitterLine className="text-3xl" />
        </a>
      </div>
      <div className="items-center justify-center p-4 h-full w-full hidden md:flex group">
        <a
          href="https://discord.com/invite/j8z9BtCADp"
          style={{
            padding: "8px",
            borderRadius: "50px",
          }}
          className="group-hover:bg-[linear-gradient(#0174d6,#012c8e)] bg-[linear-gradient(#01298b,#0a0b16)]"
        >
          <RiDiscordLine className="text-3xl" />
        </a>
      </div>
      <div className="items-center justify-center p-4 h-full w-full hidden md:flex group">
        <a
          href="https://www.youtube.com/@TickTickTrader"
          style={{
            padding: "8px",
            borderRadius: "50px",
          }}
          className="group-hover:bg-[linear-gradient(#0174d6,#012c8e)] bg-[linear-gradient(#01298b,#0a0b16)]"
        >
          <RiYoutubeLine className="text-3xl" />
        </a>
      </div>
      <div className="flex h-full w-full p-4">
        <a href="https://tickticktrader.com/">
          <img
            src={toplogcon}
            alt="toplogcon"
            className="h-full object-contain"
          />
        </a>
      </div>
    </div>
  );
}
