import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const SEARCH_URL = `${process.env.REACT_APP_BASE_API_URL}/`;

export const fetchSearchResults = createAsyncThunk(
  "search/search",
  async (args) => {
    const { search, searchType } = args;

    try {
      const response = await axios.get(
        `${SEARCH_URL}${searchType}/search?name=${search}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  searchResults: [],
  status: "idle",
  searchBarVisibility: false,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setVisibility: (state, action) => {
      state.searchBarVisibility = action.payload;
    },
    clearResults: (state) => {
      state.searchResults = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.searchResults = action.payload;
        state.searchBarVisibility = action.payload?.length > 0 ? true : false;
      })
      .addCase(fetchSearchResults.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setVisibility, clearResults } = searchSlice.actions;
export default searchSlice.reducer;
