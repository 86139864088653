import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const NEXT_EVENT_URL = `${process.env.REACT_APP_BASE_API_URL}/tournoi/upcoming`;

let initialState = {
  data: [],
  status: "idle",
  size: 0,
};

export const fetchNextEvent = createAsyncThunk(
  "nextEvent/getNextEvent",
  async () => {
    try {
      const response = await axios.get(NEXT_EVENT_URL);
      return response.data;
    } catch (error) {}
  }
);

export const getNextEventSlice = createSlice({
  name: "nextEvent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNextEvent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNextEvent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload?.docs?.slice(0, 3);
        state.size = action.payload?.docs?.length;
      })
      .addCase(fetchNextEvent.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { selectNextEvent } = (state) => state.nextEvent.data;
export default getNextEventSlice.reducer;
