import active from "../assets/PNG/up.png";
import inactive from "../assets/PNG/down.png";

import upicon from "../assets/SVG/up.svg";
import downicon from "../assets/SVG/down.svg";

import ticketicon from "../assets/PNG/ticket.png";

export const activeInactiveIcon = {
	active: active,
	inactive: inactive,
}

export const upDownIcon = {
	up: upicon,
	down: downicon,
	ticketicon: ticketicon,
}

export const fakeData = [
	{
		"id": "tr-000001",
		"accountType" : "gold",
		"rank": 1,
		"Account": "John Doe",
		"Account Balance": 1000,
		"Cash On Hand" : "1000",
		"P&L": 100,
		"Status": true,
		"Percentage": "↘ -10%"
	},
]