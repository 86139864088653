import homepage from "../assets/SVG/home-page.svg";
import homepageactive from "../assets/SVG/home-page-active.svg";
import leaderboard from "../assets/SVG/leaderboard.svg";
import leaderboardactive from "../assets/SVG/leaderboard-active.svg";
import portfolio from "../assets/SVG/portfolio.svg";
import portfolioactive from "../assets/SVG/portfolio-active.svg";
// import calendar from "../assets/SVG/calendar.svg";
// import calendaractive from "../assets/SVG/calendar-active.svg";
// import blog from "../assets/SVG/blogPage.svg";
// import blogactive from "../assets/SVG/blogPageActive.svg";
import tournament from "../assets/SVG/tournament.svg";
import tournamentactive from "../assets/SVG/tournamentactive.svg";
// import settings from "../assets/SVG/settings.svg";
// import settingsactive from "../assets/SVG/settings-active.svg";

import {
  AiTwotoneHome,
  AiTwotoneTrophy,
  AiTwotoneLayout,

  AiFillAppstore
} from "react-icons/ai";

export const c_navbar_items = [
  {
    name: "Home",
    icon: <AiTwotoneHome />,
    avtive_icon: <AiTwotoneHome />,
    link: "/",
  },
  {
    name: "Leaderboard",
    icon: <AiFillAppstore />,
    avtive_icon: <AiFillAppstore />,
    link: "/leaderboard",
  },
  // {
  //   name: "Portfolio",
  //   icon: portfolio,
  //   avtive_icon: portfolioactive,
  //   link: "/portfolio",
  // },
  {
    name: "Contests",
    icon: <AiTwotoneTrophy />,
    avtive_icon: <AiTwotoneTrophy />,
    link: "/contests",
  },
  // {
  //   name: "Calendar",
  //   icon: calendar,
  //   avtive_icon: calendaractive,
  //   link: "/calendar",
  // },
  // {
  //   name: "Blog",
  //   icon: blog,
  //   avtive_icon: blogactive,
  //   link: "/blog",
  // },
  // {
  //   name: "Settings",
  //   icon: settings,
  //   avtive_icon: settingsactive,
  //   link: "/settings",
  // },
];
