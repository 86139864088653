import logo from "../assets/PNG/contest.png";

export const fakeActivityFeed = [
	{
		id: "1",
		icon : logo,
		title : "Elimination",
		sub : "#NickName has been eliminated due to violation of closing positions.",
	},
	{
		id: "1",
		icon : logo,
		title : "Elimination",
		sub : "#NickName has been eliminated due to violation of closing positions.",
	},
	{
		id: "1",
		icon : logo,
		title : "Elimination",
		sub : "#NickName has been eliminated due to violation of closing positions.",
	},
	{
		id: "1",
		icon : logo,
		title : "Elimination",
		sub : "#NickName has been eliminated due to violation of closing positions.",
	},
	{
		id: "1",
		icon : logo,
		title : "Elimination",
		sub : "#NickName has been eliminated due to violation of closing positions.",
	},
	{
		id: "1",
		icon : logo,
		title : "Elimination",
		sub : "#NickName has been eliminated due to violation of closing positions.",
	},
	{
		id: "1",
		icon : logo,
		title : "Elimination",
		sub : "#NickName has been eliminated due to violation of closing positions.",
	},
	{
		id: "1",
		icon : logo,
		title : "Elimination",
		sub : "#NickName has been eliminated due to violation of closing positions.",
	},
]