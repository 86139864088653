import React from "react";
import { Link } from "react-router-dom";

export default function PortfolioCard(params) {
  let { nickname, thumbnail, id, isTour } = params;

  return (
    <div className="bg-gradient-to-br from-border-color to-transparent h-64 w-64 rounded-3xl border-2 border-border-color p-2  flex flex-col justify-between cursor-pointer">
      <div className="relative h-64 rounded-2xl overflow-hidden m-2 flex items-center justify-center">
        {thumbnail.length <= 2 ? (
          <h1 className="text-[100px] font-black">{thumbnail}</h1>
        ) : (
          <img
            src={thumbnail}
            alt=""
            className="h-full w-full object-contain"
          />
        )}
      </div>
      <div className="text-center font-bold uppercase mb-4">
        <h1>{nickname}</h1>
      </div>
      {isTour && (
        <Link
          to={`${isTour ? "/contests/" : "/portfolio/"}statistics/${id}`}
        >
          <button
            className=" w-full p-2 rounded-xl mb-1 border-2 border-border-color"
            style={{
              backgroundImage:
                "linear-gradient(180deg, #0174d6 0%, #012c8e 100%)",
            }}
          >
            Statistics
          </button>
        </Link>
      )}
    </div>
  );
}
