import React from "react";
import logo from "../assets/PNG/contest.png";
import { fakeActivityFeed } from "../context/C_ActivityFeed";

export default function ActivityFeed() {
  return (
    <div className=" relative w-96 h-[36rem] bg-dark-color overflow-y-scroll rounded-3xl rounded-tl-none">
      <div className="grid grid-cols-[6rem,_auto] gap-2 p-2">
        <div>
          <img src={logo} alt="" />
        </div>
        <div className="flex justify-center flex-col">
          <div>
            <h1>Activity Feed</h1>
          </div>
          <div>
            <p className="text-cyan-500">Notifications</p>
          </div>
        </div>
      </div>
      <div>
        <ul>
          {fakeActivityFeed?.map((item, index) => (
            <li key={index}>
              <div className="grid grid-cols-[6rem,_auto] gap-2 p-2 bg-[linear-gradient(_transparent,#1D5F85)] rounded-b-3xl">
                <div>
                  <img src={item.icon} alt="" />
                </div>
                <div className="flex justify-center flex-col">
                  <div>
                    <h1>{item.title}</h1>
                  </div>
                  <div>
                    <p className="text-cyan-500">{item.sub}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
