import { createSlice } from "@reduxjs/toolkit";

export const showHideFeedSlice = createSlice({
  name: "showHideFeed",
  initialState: {
    value: false,
  },
  reducers: {
    showHideFeed: (state) => {
      state.value = !state.value;
    },
  },
});

export const { showHideFeed } = showHideFeedSlice.actions;
export default showHideFeedSlice.reducer;
