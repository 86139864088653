import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const USER_URL = `${process.env.REACT_APP_BASE_API_URL}/user`;

let initialState = {
  data: [],
  status: "idle",
};

export const fetchUsers = createAsyncThunk("user/getUser", async () => {
  try {
    const response = await axios.get(USER_URL);
    return response.data;
  } catch (error) {}
});

export const getListUsersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { selectUsers } = getListUsersSlice.actions;
export default getListUsersSlice.reducer;
